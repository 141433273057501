import ajax from '@/utils/ajax.js'

/**
 * 获取运费模板列表
 * @param {*} params 
 * @returns 
 */
export function getFreightTypeList(params) {
  return ajax.post('/FreightType/getList',params)
}


/**
 * 添加运费模板
 * @type {*} 
 * 
 * @returns 
 */
 export function addFreightType(params) {
  return ajax.post('/FreightType/add',params)
}


/**
 * 修改模板
 * @returns 
 */
 export function updateFreightType(params) {
  return ajax.post('/FreightType/update',params)
}

/**
 * 修改模板
 * @returns 
 */
 export function updateFreightStatus(params) {
  return ajax.post('/FreightType/updateStatus',params)
}

/**
 * 获取商品使用列表
 * @returns 
 */
 export function getFreightUseGoodsList(params) {
  return ajax.post('/FreightType/useGoodsList',params)
}


/**
 * 获取运费模板列表
 * @param {*} params 
 * @returns 
 */
 export function getTempList(params) {
  return ajax.post('/FreightTypeTempPolicy/getList',params)
}


/**
 * 添加运费模板
 * @type {*} 
 * 
 * @returns 
 */
 export function addTempFreight(params) {
  return ajax.post('/FreightTypeTempPolicy/add',params)
}


/**
 * 修改模板
 * @returns 
 */
 export function updateTempFreight(params) {
  return ajax.post('/FreightTypeTempPolicy/update',params)
}
