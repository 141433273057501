<template>
  <div>
    <base-table ref="tableRef" :columnsData="columns" rowKey="id" :tableData="tableData">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">运费模板</h4>
        </div>
        <div class="flex justify-between">
          <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
            <a-tab-pane v-for="item in typeList" :key="item.type_id" :tab="item.type_name"></a-tab-pane>
          </a-tabs>
          <a-button class="ml-3" type="primary" @click="handlerEdit('add')" html-type="submit">新增</a-button>
        </div>
      </template>

      <template #status="{text}">
        <a-tag class="mr-0" :color="text===1? 'green' : 'red' ">{{text==1? '正常' : '停用'}}</a-tag>
      </template>
      <template #operation="{record}">
        <a @click="handlerEdit('edit',record)">编辑</a>
        <a class="ml-3" @click="handlerTemp(record)">临时策略</a>
        <a class="ml-3" @click="updateStatus(record)">{{record.status == 1? '停用' : '恢复'}}</a>
        <a class="ml-3" style="color: #1890ff;" @click="handlerDelete(record)">删除</a>
      </template>
    </base-table>

    <!-- 新增 编辑modal -->
    <a-modal
      v-model="isEidtShow"
      width="400px"
      :title="type=='add'?'新增运费模板': '编辑运费模板'"
      @ok="handlerSave"
    >
      <a-form-model
        ref="addForm"
        :labelCol="{ span: 4 }"
        :wrapperCol="{ span: 20 }"
        :model="freightForm"
      >
        <a-form-model-item class="mb-2" prop="name" label="模板备注名">
          <a-input allowClear v-model="freightForm.name" placeholder="请输入模板备注名"></a-input>
        </a-form-model-item>

        <a-form-model-item class="mb-2" prop="free_delivery_threshold" label="包邮模式">
          <a-select v-model="free_delivery_mode" placeholder="选择类型">
            <a-select-option value="0">包邮</a-select-option>
            <a-select-option value="1">满额包邮</a-select-option>
            <a-select-option value="2">固定运费</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item class="mb-2" prop="freight" label="运费价格"
          v-if="free_delivery_mode!=0">
          <a-input-number style="width:100%;"
            :step="0.01" allowClear
            v-model="freightForm.freight"
            placeholder="请输入运费价格"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item class="mb-2" prop="free_delivery_threshold" label="包邮门槛" 
          v-if="free_delivery_mode==1">
          <a-input-number style="width:100%;"
            :step="0.01" allowClear
            v-model="freightForm.free_delivery_threshold"
            placeholder="请输入包邮门槛"
          ></a-input-number>
        </a-form-model-item>

      </a-form-model>
      <a-button type="link" class="p-0 mt-2" v-if="type!='add'" @click="handlerIsShopShow">查看适用商品</a-button>
    </a-modal>

    <!-- 查看商品modal -->
    <a-modal v-model="isShopShow" width="500px" title="适用商品" :footer="null">
      <base-table
        ref="shopRef"
        :columnsData="shopColumns"
        rowKey="goods_id"
        :tableData="shopTableData"
      >
      </base-table>
    </a-modal>
  </div>
</template>

<script>
import {
  getFreightTypeList,
  addFreightType,
  updateFreightType,
  getFreightUseGoodsList,
  updateFreightStatus
} from "@/api/activity/freight"
import { formatGoodsType } from "@/utils/type";

export default {
  data() {
    return {
      formatGoodsType,
      columns: [
        {
          title: "模板备注名",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "运费价格",
          dataIndex: "freight",
          align: "center",
          width: "10%",
        },
        {
          title: "模式",
          dataIndex: "free_delivery_threshold",
          align: "center",
          width: 120,
          slots: {
            customRender: 'free_delivery_threshold'
          },
          slotsType: 'all',
          slotsFunc: (val) => this.getMode(val)
        },
        {
          title: "模板状态",
          dataIndex: "status",
          align: "center",
          width: "10%",
          slots: {
            customRender: "status",
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: { customRender: "operation" },
          align: "center",
          width: "25%",
        },
      ],
      shopColumns: [
        // {
        //   title: "商品编码",
        //   dataIndex: "goods_number",
        //   align: "center",
        // },
        {
          title: "产品属性",
          dataIndex: "type",
          align: "center",
          width: 90,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
      ],
      freightForm: {
        name: "",
        freight: "",
        free_delivery_threshold: "",
      },
      free_delivery_mode: "",

      type: "add",
      shopTableData: [],
      tableData: [],
      tableForm: {
        status: "",
      },
      isEidtShow: false,
      isShopShow: false,
      activeKey: "",
      typeList: [
        { type_id: "", type_name: "全部" },
        { type_id: 1, type_name: "在使用" },
        { type_id: 0, type_name: "已停用" },
      ],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { data, code } = await getFreightTypeList(this.tableForm)
      if (code === 0) {
        this.tableData = data.list
      }
    },
    updateStatus(row) {
      let status = row.status == 1 ? 0 : 1
      updateFreightStatus({
        id:row.id,
        status: status
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功")
          this.initData()
        }
      })
    },

    handlerDelete(row){
      updateFreightStatus({
        id:row.id,
        status: -1
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("已删除")
          this.initData()
        }
      })
    },

    handlerSave() {
      if(!this.checkFreightForm()){
        return
      }
      
      if (this.type === "add") {
        addFreightType(this.freightForm).then((res) => {
          if (res.code === 0) {
            this.$message.success("新增运费模板成功！")
            this.isEidtShow = false
            this.initData()
          }
        })
      } else {
        updateFreightType(this.freightForm).then((res) => {
          if (res.code === 0) {
            this.$message.success("修改运费模板成功！")
            this.isEidtShow = false
            this.initData()
          }
        })
      }
    },
    handlerEdit(type, row) {
      this.type = type
      if (row) {
        this.freightForm = JSON.parse(JSON.stringify(row))
        this.formatToMode()
      } else {
        this.freightForm.name = ""
        this.freightForm.freight = ""
        this.freightForm.free_delivery_threshold = ""
        this.free_delivery_mode = ""
      }
      this.isEidtShow = true
    },
    handlerTemp(e) {
      this.$router.push({ path: "/freight-temp", query: { freightID: e.id } })
    },
    handlerIsShopShow() {
      this.isShopShow = true
      let params = {
        freight_type_id: this.freightForm.id,
        page: 1,
      }
      getFreightUseGoodsList(params).then((res) => {
        if (res.code === 0) {
          this.shopTableData = res.data.list
        }
      })
    },
    handlerTypeChange(e) {
      this.tableForm.status = e
      // this.tableForm.date_type = e
      this.activeKey = e
      this.initData()
    },

    // 包邮门槛 和 包邮模式 换算
    formatToMode(){
      if(Number(this.freightForm.freight) == 0){ // 包邮
        this.free_delivery_mode = "0"
      }else if(Number(this.freightForm.free_delivery_threshold) == 0){ // 固定运费
        this.free_delivery_mode = "2"
      }else{ // 满额包邮
        this.free_delivery_mode = "1"
      }
    },
    checkFreightForm(){
      if(this.free_delivery_mode == "0"){ // 包邮
        this.freightForm.free_delivery_threshold = 0
        this.freightForm.freight = 0
      }else if(this.free_delivery_mode == "2"){ // 固定运费
        if(Number(this.freightForm.freight) == 0){
          this.$message.warning("运费不能为0")
          return false
        }
        this.freightForm.free_delivery_threshold = 0
      }else{ // 满额包邮
        this.free_delivery_mode = "1"
        if(Number(this.freightForm.freight) == 0){
          this.$message.warning("运费不能为0")
          return false
        }
        if(Number(this.freightForm.free_delivery_threshold) == 0){
          this.$message.warning("包邮门槛不能为0")
          return false
        }
      }
      return true
    },
    getMode(item){
      if(Number(item.freight) == 0){ // 包邮
        return "包邮"
      }else if(Number(item.free_delivery_threshold) == 0){ // 固定运费
        return "固定运费"
      }else{ // 满额包邮
        return "满"+Number(item.free_delivery_threshold)+"包邮"
      }
    }

  },
}
</script>

<style>
</style>